const TaxCal = {
    taxBase: [
        {
            minIncome: 0,
            maxIncome: 150000,
            netIncome: 150000,
            percent: 0,
            maxTax: 0,
            maxTotal: 0,
        },
        {
            minIncome: 150001,
            maxIncome: 300000,
            netIncome: 150000,
            percent: 5,
            maxTax: 7500,
            maxTotal: 7500,
        },
        {
            minIncome: 300001,
            maxIncome: 500000,
            netIncome: 200000,
            percent: 10,
            maxTax: 20000,
            maxTotal: 27500,
        },
        {
            minIncome: 500001,
            maxIncome: 750000,
            netIncome: 250000,
            percent: 15,
            maxTax: 37500,
            maxTotal: 65000,
        },
        {
            minIncome: 750001,
            maxIncome: 1000000,
            netIncome: 250000,
            percent: 20,
            maxTax: 50000,
            maxTotal: 115000,
        },
        {
            minIncome: 1000001,
            maxIncome: 2000000,
            netIncome: 1000000,
            percent: 25,
            maxTax: 250000,
            maxTotal: 365000,
        },
        {
            minIncome: 2000001,
            maxIncome: 5000000,
            netIncome: 3000000,
            percent: 30,
            maxTax: 900000,
            maxTotal: 1265000,
        },
        {
            minIncome: 5000001,
            maxIncome: null,
            netIncome: null,
            percent: 35,
            maxTax: null,
            maxTotal: null,
        },
    ],

    deduction: {
        percent_deduction: 50,
        max_percent_deduction: 100000,
        person_deduction: 60000
    },

    find_net_wage: function (income) {
        let deduct = (income * this.deduction.percent_deduction) / 100;
        if (deduct > this.deduction.max_percent_deduction) {
            deduct = this.deduction.max_percent_deduction;
        }
        return income - (deduct + this.deduction.person_deduction);
    },

    cal_tax: function (net_wage) {
        let payVat = 0;

        if (net_wage >= this.taxBase[this.taxBase.length - 1].minIncome) {
            return {
                base: net_wage,
                pay: (net_wage * this.taxBase[this.taxBase.length - 1].percent) / 100
            };
        }

        let stepLeft = net_wage;

        for (let val of this.taxBase) {
            if (stepLeft >= val.netIncome) {
                payVat += (val.netIncome * val.percent) / 100;
            } else {
                payVat += (stepLeft * val.percent) / 100;
                break;
            }
            stepLeft -= val.netIncome;
        }

        let returnBase = net_wage;
        if (returnBase < 0) {
            returnBase = 0;
        }

        return {
            base: returnBase,
            pay: payVat,
        };
    }
}

export default TaxCal;