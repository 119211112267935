import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import logo from './logo.svg'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import Drawer from '@mui/material/Drawer';
import TaxCal from './calfn';
import Menu from '@mui/material/Menu';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';

export default function Landing() {

    const taxCal = TaxCal;
    const [firstDeduct, setFirstDeduct] = useState(true);
    const [secondDeduct, setSecondDeduct] = useState(true);
    const [payVat, setPayvat] = useState(0);
    const [netWage, setNetwage] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [socialValue, setSocialValue] = useState('9,000');
    const [otherDeduction, setotherDeduction] = useState('0');
    const [state, setState] = useState({
        right: false,
    });

    function fixedNUM(num, precision = 2) {
        if (isNaN(num)) {
            return '0.00';
        }
        var roundedx = (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        var commasx = roundedx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return commasx;
    }

    useEffect(() => {

        let input = parseInt(inputValue.replace(/,/g, ''));
        let social = parseInt(socialValue.replace(/,/g, ''));
        let other = parseInt(otherDeduction.replace(/,/g, ''));

        let deduct = 0;
        if (firstDeduct) {
            deduct = (input * taxCal.deduction.percent_deduction) / 100;
            if (deduct > taxCal.deduction.max_percent_deduction) {
                deduct = taxCal.deduction.max_percent_deduction;
            }
        }

        let deduct2 = 0;
        if (secondDeduct) {
            deduct2 = taxCal.deduction.person_deduction
        }

        let newWage = input - (deduct + deduct2 + social + other);
        let calData = taxCal.cal_tax(newWage);

        setNetwage(fixedNUM(calData.base));
        setPayvat(fixedNUM(calData.pay));

    }, [inputValue, socialValue, otherDeduction, firstDeduct, secondDeduct, taxCal]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div id="main">
            <div className="flex justify-between items-center p-4" style={{ boxShadow: '0 0 120px 0 rgb(0 0 0 / 15%)' }}>
                <img src={logo} className="w-24" alt="logo" />
                <div className="flex gap-4">
                    <button onClick={toggleDrawer('right', true)}>
                        <MenuOutlinedIcon />
                    </button>
                    <button onClick={handleClick}>
                        <GridViewOutlinedIcon />
                    </button>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <div className="grid grid-cols-2 gap-4 p-8">
                            <div className="text-center">
                                <a href="https://password-generator.8content.com/">
                                    <div className="w-28 h-28 rounded-full ring-gray-300 bg-indigo-800 flex justify-center items-center mx-auto">
                                        <PasswordOutlinedIcon sx={{ color: '#ffffff', fontSize: 60 }} />
                                    </div>
                                    <p className="my-4">สร้างรหัสผ่านปลอดภัย</p>
                                </a>
                            </div>
                            <div className="text-center">
                                <a href="https://qrcode-generator.8content.com/">
                                    <div className="w-28 h-28 rounded-full ring-gray-300 bg-indigo-800 flex justify-center items-center mx-auto">
                                        <QrCode2OutlinedIcon sx={{ color: '#ffffff', fontSize: 60 }} />
                                    </div>
                                    <p className="my-4">สร้าง QR Code ออนไลน์</p>
                                </a>
                            </div>
                        </div>
                    </Menu>
                </div>
            </div>
            <div className="container mx-auto p-8">
                <div className="md:flex gap-4 items-center" style={{ minHeight: 'calc(100vh - 150px)' }}>
                    <div className="md:w-5/12 md:px-8 w-12/12">
                        <h1 className="md:text-2xl text-xl font-bold mb-2">คำนวณภาษีเงินได้แบบขั้นบันไดออนไลน์</h1>
                        <p className="mb-8">กรอกรายได้ และยอดลดหย่อนต่างๆ เพื่อคำนวณภาษี</p>

                        <FormGroup className="mb-4">
                            <FormControlLabel
                                label="หักค่าใช้จ่าย 50% หรือไม่เกิน 100,000 บาท"
                                control={
                                    <Switch
                                        checked={firstDeduct}
                                        onChange={(e) => setFirstDeduct(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                            />
                        </FormGroup>
                        <FormGroup className="mb-6">
                            <FormControlLabel
                                label="หักค่าลดหย่อน 60,000 บาท"
                                control={
                                    <Switch
                                        checked={secondDeduct}
                                        onChange={(e) => setSecondDeduct(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                            />
                        </FormGroup>

                        <div className="relative mb-4">
                            <label className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">ลดหย่อนอื่นๆ</label>
                            <NumericFormat
                                placeholder="0.00"
                                className="border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-800 focus:border-indigo-800 outline-0 block w-full p-2 pl-28 pr-12 text-right"
                                thousandSeparator=","
                                value={otherDeduction}
                                onChange={(e) => setotherDeduction(e.target.value)}
                            />
                            <div className="absolute right-1 bottom-0.5 outline-0 font-medium text-sm px-4 py-2">บาท</div>
                        </div>

                        <div className="relative mb-4">
                            <label className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">ยอดประกันสังคมรวม</label>
                            <NumericFormat
                                placeholder="0.00"
                                className="border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-800 focus:border-indigo-800 outline-0 block w-full p-2 pl-28 pr-12 text-right"
                                thousandSeparator=","
                                value={socialValue}
                                onChange={(e) => setSocialValue(e.target.value)}
                            />
                            <div className="absolute right-1 bottom-0.5 outline-0 font-medium text-sm px-4 py-2">บาท</div>
                        </div>

                        <div className="relative mb-4">
                            <label className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">รายได้รวมทั้งปี</label>
                            <NumericFormat
                                placeholder="0.00"
                                className="border text-gray-900 rounded-lg ring-indigo-800 border-indigo-800 focus:ring-indigo-800 focus:border-indigo-800 outline-0 block w-full p-2  pl-28 pr-12 text-right"
                                thousandSeparator=","
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                autoFocus
                            />
                            <div className="absolute right-1 bottom-0.5 outline-0 font-medium text-sm px-4 py-2">บาท</div>
                        </div>
                        <p className="flex justify-between p-3.5 pb-2">
                            <span>รายได้สุทธิ:</span>
                            <span className="text-lg font-bold">{netWage} <small className="font-normal">บาท</small></span>
                        </p>
                        <p className="flex justify-between p-3.5 pt-2">
                            <span>จำนวนภาษีที่ต้องจ่าย:</span>
                            <span className="text-lg font-bold ">{payVat} <small className="font-normal">บาท</small></span>
                        </p>
                    </div>
                    <div className="md:w-7/12 w-full">
                        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
                            <CardContent>

                                <div className="relative overflow-x-auto" >
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                                    เงินได้สุทธิ
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-center whitespace-nowrap">
                                                    เงินได้สุทธิสูงสุด
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-center whitespace-nowrap">
                                                    อัตราภาษี
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-right whitespace-nowrap">
                                                    ภาษีสูงสุด
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-right whitespace-nowrap">
                                                    ภาษีสะสมสูงสุด
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(taxCal.taxBase).map(([k, item]) => (
                                                <tr key={k} className="bg-white border-t ">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                        {fixedNUM(item.minIncome, 0)} {item.maxIncome ? '- ' + fixedNUM(item.maxIncome, 0) : 'ขั้นไป'}
                                                    </th>
                                                    <td className="px-6 py-4 text-center">
                                                        {item.netIncome ? fixedNUM(item.netIncome, 0) : '-'}
                                                    </td>
                                                    <td className="px-6 py-4 text-center">
                                                        {item.percent ? item.percent + '%' : '-'}
                                                    </td>
                                                    <td className="px-6 py-4 text-right">
                                                        {item.maxTax ? fixedNUM(item.maxTax, 0) : '-'}
                                                    </td>
                                                    <td className="px-6 py-4 text-right">
                                                        {item.maxTotal ? fixedNUM(item.maxTotal, 0) : '-'}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <div className="p-8" style={{ minWidth: '250px' }}>
                    <ul className="text-xl space-y-4">
                        <li><a href="https://8content.com/">หน้าหลัก</a></li>
                        <li><a href="https://8content.com/%e0%b8%a3%e0%b8%b1%e0%b8%9a%e0%b8%97%e0%b8%b3%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%84%e0%b8%8b%e0%b8%95%e0%b9%8c-2/">เกี่ยวกับเรา</a></li>
                        <li><a href="https://8content.com/services/">บริการของเรา</a></li>
                        <li><a href="https://8content.com/blog/">บทความ</a></li>
                        <li><a href="https://8content.com/%e0%b8%95%e0%b8%b4%e0%b8%94%e0%b8%95%e0%b9%88%e0%b8%ad%e0%b9%80%e0%b8%a3%e0%b8%b2/">ติดต่อเรา</a></li>
                    </ul>
                </div>
            </Drawer>
        </div>
    );
}
